import logo from './logo.svg';
import './App.css';
import DocheartLogoDisplay from './DocheartLogoDisplay';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <DocheartLogoDisplay theme={'default'} />
        <p>developed by Docklab</p>
        <a href="https://app.docheart.ai" className='link'>Go to app</a>
     
      </header>
    </div>
  );
}

export default App;
