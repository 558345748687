
import docheartLogo from "./assets/docheart-logo-light-bg.svg";
import docheartLogoDark from "./assets/docheart-logo-dark-bg.svg"

const DocheartLogoDisplay = ({theme}) => {
    
    return (<div className='w-[613px] h-[104px] flex justify-center'>
        {theme === 'default'?
            <img className='w-[70%]' src={docheartLogo} />:
            <img className='w-[70%]' src={docheartLogoDark} />
        }
    </div>)
}

export default DocheartLogoDisplay;